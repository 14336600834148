<template>
  <section title="">
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                xl="9"
                md="9"
              >
                <h2>Detalhes do Pedido</h2>
              </b-col>
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <!--                    <slot v-if="column.label === 'Ações'">-->
                    <!--                      <b-button-->
                    <!--                        v-b-modal.addempresas-->
                    <!--                        variant="outline-primary"-->
                    <!--                        size="sm"-->
                    <!--                        class="btn-icon rounded-circle"-->
                    <!--                        @click="EditarDados(item)"-->
                    <!--                      >-->
                    <!--                        <feather-icon icon="EditIcon" />-->
                    <!--                      </b-button>-->
                    <!--                      <b-button-->
                    <!--                        variant="outline-danger"-->
                    <!--                        size="sm"-->
                    <!--                        class="btn-icon rounded-circle"-->
                    <!--                        @click="DeletarDados(item)"-->
                    <!--                      >-->
                    <!--                        <feather-icon icon="Trash2Icon" />-->
                    <!--                      </b-button>-->
                    <!--                    </slot>-->
                    <slot v-if="column.label === 'Detalhes'">
                      <b-button
                        variant="outline-primary"
                        size="sm"
                        class="btn-icon rounded-circle"
                        @click="Detalhes(item)"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                    </slot>
                  <!--                    <slot v-if="column.label == 'Status'">-->
                  <!--                      <b-badge-->
                  <!--                        :variant="NomeStatus(item).cor"-->
                  <!--                      >-->
                  <!--                        {{ NomeStatus(item).msg }}-->
                  <!--                      </b-badge>-->
                  <!--                    </slot>-->
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
        </b-col>
      </b-row>
      <b-modal
        v-if="mostraPopUp"
        id="addcategorias"
        title="Adicione"
        hide-footer
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Nome"
              label-for="Nome"
            >
              <b-form-input
                id="Nome"
                v-model="nomeCategorias"
                placeholder="Nome da Categoria"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Descrição"
              label-for="desc"
            >
              <b-form-input
                id="desc"
                v-model="descCategorias"
                placeholder="Descrição da Categoria"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>

            <b-form-group
              label="Status"
              label-for="selecionetipo"
            >
              <v-select
                v-model="categoriaTipo"
                label="text"
                :options="opcoesTipo"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="SalvarDados"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-card>
    <b-card>
      <b-card-body>
        <b-row>
          <b-col
            md="6"
            class="mt-1"
            style="margin-left: 14%"
          >
            <h4>
              Valor Total : R$ {{ detalhespedidos.total_pedidos }}
            </h4>
          </b-col>
          <b-col
            md="6"
            class="mt-1"
            style="margin-left: -14%"
          >
            <h4>
              Valor Desconto : R$ {{ detalhespedidos.pedidodesconto }}
            </h4>
          </b-col>
          <!--          <b-col md="4">-->
          <!--            <h4>-->
          <!--              <b-button-->
          <!--                v-b-modal.finalizarpedido-->
          <!--                variant="primary"-->
          <!--                block-->
          <!--              >-->
          <!--                Finalizar Pedido-->
          <!--              </b-button>-->
          <!--            </h4>-->
          <!--          </b-col>-->
        </b-row>
      </b-card-body>

      <b-modal
        v-if="mostraPopUp"
        id="finalizarpedido"
        title="Finalize o Pedido"
        hide-footer
      >
        <b-row>
          <b-col>
            <b-form-group
              label="Nome"
              label-for="Nome"
            >
              <b-form-input
                id="Nome"
                v-model="nomeCategorias"
                placeholder="Nome da Categoria"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Descrição"
              label-for="desc"
            >
              <b-form-input
                id="desc"
                v-model="descCategorias"
                placeholder="Descrição da Categoria"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>

            <b-form-group
              label="Status"
              label-for="selecionetipo"
            >
              <v-select
                v-model="categoriaTipo"
                label="text"
                :options="opcoesTipo"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2 mb-2">
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="SalvarDados"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-card></section>

</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { VBToggle } from 'bootstrap-vue'
import Vue from 'vue'
import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  data() {
    return {
      id: null,
      categoriaTipo: '',
      nomeCategorias: '',
      descCategorias: '',
      detalhespedidos: [],
      mostraPopUp: true,
      url: `pedidositem/${JSON.parse(localStorage.getItem('pedido')).id}`,
      data: {},
      dadositem: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      opcoesTipo: [
        { value: 1, text: 'Ativo' },
        { value: 2, text: 'Inativo' },
      ],
      columns: [
        {
          label: '#',
          name: 'id',
          orderable: true,
        },
        {
          label: 'produto',
          name: 'produto.nome',
          orderable: true,
        },
        {
          label: 'quantidade',
          name: 'quantidade',
          orderable: true,
        },
        {
          label: 'valor unidade',
          name: 'valor_und',
          orderable: true,
        },
        // {
        //   label: 'Ações',
        //   filterable: false,
        // },
      ],
    }
  },
  async created() {
    this.detalhespedidos = JSON.parse(localStorage.getItem('pedido'))
    await this.BuscarDados(this.url)
  },
  methods: {

    EditarDados(item) {
      localStorage.setItem('dados_empresa', JSON.stringify(item))
      const dados = JSON.parse(localStorage.getItem('dados_empresa'))
      this.id = dados.id
      this.nomeEmpresa = dados.nome
      this.categoriaTipo = dados.status
      if (this.categoriaTipo === 1) {
        this.categoriaTipo = { text: 'Ativo' }
      } else {
        this.categoriaTipo = { text: 'Inativo' }
      }
      this.mostraPopUp = true
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'ALERTA',
        variant: cor,
        solid: true,
      })
    },

    ZerarCampos() {
      const obj = {
        id: this.id,
        nome: this.nomeEmpresa,
        status: this.categoriaTipo.value,
      }
      this.nomeEmpresa = null
      this.categoriaTipo = null
      return obj
    },
    SalvarDados() {
      if (this.categoriaTipo === null) {
        this.Notificacao('danger', 'Selecione o Status da Empresa')
        return
      }
      const obj = this.ZerarCampos()
      if (this.id === null) {
        // eslint-disable-next-line no-unused-vars
        this.$http.post('admin/empresas', obj).then(resp => {
          this.mostraPopUp = false
          this.reloadTable()
        })
        return
      }
      this.$http.put(`admin/empresas/${this.id}`, obj).then(resp => {
        this.data = resp.data
        this.reloadTable()
      })
    },
    Detalhes(item) {
      localStorage.setItem('pedido', JSON.stringify(item.id))
      this.$router.push({ name: 'app-pedidosdetalhes' })
    },
    editDados(item) {
      localStorage.setItem('empresa', JSON.stringify(item))
      this.$router.push({
        name: 'app-membros',
      })
    },
    NomeStatus(item) {
      if (item.status === 2) {
        return { cor: 'light-danger', msg: 'Inativo' }
      }
      if (item.status === 1) {
        return { cor: 'light-success', msg: 'Ativo' }
      }
      if (item.status === 0) {
        return { cor: 'light-warning', msg: 'Fornecedor' }
      } return 'Sem Status'
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
      this.$loading(false)
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    DeletarDados(item) {
      this.$http.delete(`admin/leads/${item.id}`)
      this.reloadTable()
      return ['excluido com sucesso']
    },
  },
}
</script>

<style scoped>

</style>
