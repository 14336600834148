import { render, staticRenderFns } from "./pedidos-detalhes.vue?vue&type=template&id=07c1b575&scoped=true&"
import script from "./pedidos-detalhes.vue?vue&type=script&lang=js&"
export * from "./pedidos-detalhes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c1b575",
  null
  
)

export default component.exports